.App {
  text-align: center;
  min-height: 100%;

  background-color: #343150;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header{
  height: 70px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 2em;
  box-sizing: border-box;

  position: sticky;
  top: 0;
  z-index: 100;

  background-color: #0f0e17;
  color: #f0f0f0;
}


p, h1, h2, h3, h4, h5, h6, img{
  color: #f0f0f0;
  font-size: 1em;
  font-weight: 700;
}

h2{
  font-size: 1.5em;
  width: 100%;
  text-decoration: underline;
}

/* BATTERIE */
.battery_section{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  padding: 1em;
}
.battery{
  position: relative;
  min-width: 1em;
  height: 100%;
  min-width: 30px;
}

.battery > *{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  margin: 0;
}

.battery > img{
  background-color: #f0f0f0;
  border-radius: 30%;
  border: 2px solid #f0f0f0;
  object-fit: contain;
}

.battery > p{
  font-size: 0.5em;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no_connection{
  color: red;
}

.data_header{
  min-height: 70px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;

}

.data_header i{
  color: #f0f0f0;
  font-size: 1em;
  min-width: 1em;
}

.camera_section{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 1em;
  box-sizing: border-box;
}

.camera{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.buttons_camera{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

#canvas{
  max-width: 80%;
  height: 100%;
  contain: cover;
  background-color: #f0f0f0;
}

@media screen and (max-width: 768px){
  .camera_section{
    flex-direction: column;
  }

  .camera{
    flex-direction: column;
  }

  .buttons_camera{
    flex-direction: column;
  }
}



.galerie{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1em;
  padding: 1em;
  box-sizing: border-box;
}

.galerie img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.black{
  color: black !important;
}